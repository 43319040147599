<template>
    <div id="pageTable" v-if="rol == 'root'">      
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Perfiles de facturación'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Registro patronal"
                                    :items="itemsRegistrosPatronales"
                                    item-text="claveRegistroPatronal"
                                    item-value="id"
                                    persistent-hint
                                    v-model="filterRegistroPatronal"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0" >
                                <v-text-field
                                    outlined
                                    label="Correo"
                                    v-model="filterCorreo"
                                ></v-text-field>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div> {{item.registroPatronal.clave}} - {{item.registroPatronal.nombre}} </div></td>
                                <td><div> {{item.email}} </div></td>
                                <td><div> {{item.ilimitado ? 'SI':'NO'}} </div></td>
                                <td><div> {{item.timbres}} </div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    @click="abrirModal('actualizarTimbres', item)"
                                                >
                                                    <v-icon class="tamIconoBoton iconoDelgadoBoton" v-text="'$notifications_outline'"></v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Administrar timbres</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn 
                                                    v-on="on" 
                                                    class="botonHover" 
                                                    icon 
                                                    fab 
                                                    dark 
                                                    small 
                                                    @click="abrirModal('update', item)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    @click="abrirModal('cambioContrasenia', item)"
                                                >
                                                    <v-icon class="tamIconoBoton iconoDelgadoBoton" v-text="'$lock_outline'"></v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar contraseña</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn 
                                                    v-on="on" 
                                                    class="botonHover" 
                                                    icon 
                                                    fab 
                                                    dark 
                                                    small 
                                                    @click="eliminar(item)"
                                                >
                                                    <v-icon class="tamIconoBoton iconoDelgadoBotonDelete" v-text="'$person_off_outline'"></v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Desactivar usuario</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    required 
                                                    :error-messages="errors" >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Registro patronal"
                                                    :items="itemsRegistrosPatronales"
                                                    item-text="claveRegistroPatronal"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="perfilFacturacion.registro_patronal_id"
                                                    required 
                                                    :error-messages="errors" >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Correo electronico" rules="required|email">
                                                <v-text-field 
                                                    outlined
                                                    label="Correo electronico" 
                                                    class="pa-0 ma-0 code" 
                                                    v-model="perfilFacturacion.email" 
                                                    :error-messages="errors" 
                                                    required
                                                    @keyup="lowercase" 
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="accion=='add'" cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Contraseña" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Contraseña" 
                                                    class="pa-0 ma-0"
                                                    v-model="perfilFacturacion.password" 
                                                    :error-messages="errors" 
                                                    required
                                                    :append-icon="showContrasenia ? 'mdi-eye' : 'mdi-eye-off'"
                                                    @click:append="showContrasenia = !showContrasenia"
                                                    :type="showContrasenia ? 'text' : 'password'"
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <p class="tituloOtrasAcciones">Cantidad de timbres</p>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="perfilFacturacion.ilimitado" label="Timbres ilimitados"></v-checkbox>
                                        </v-col>
                                        <v-col v-if="accion=='add'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <v-text-field 
                                                outlined 
                                                label="Cantidad" 
                                                v-model.number="perfilFacturacion.timbres"
                                                v-mask="'############'"
                                                type="number"
                                                :disabled="perfilFacturacion.ilimitado"
                                            >    
                                            </v-text-field>
                                        </v-col>
                                        
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogAcciones" persistent max-width="520px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalAcciones"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="padding_izquierdo padding_derecho card_titulo">
                                <div class="headerModal">
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModalAcciones">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModalAcciones" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row class="pa-0 ma-0">
                                        <template v-if="accion == 'cambioContrasenia'">
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0">
                                                <ValidationProvider v-slot="{ errors }" name="Contraseña" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        label="Contraseña"
                                                        :type="show1 ? 'text' : 'password'"
                                                        v-model="contraseniaActualizar"
                                                        @keyup.enter="actualizarContrasenia()"
                                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                        @click:append="show1 = !show1"
                                                        :error-messages="errors" 
                                                        required
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </template>

                                        <template v-if="accion == 'actualizarTimbres'">
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                                <v-container class="ma-0 pa-0" fluid>
                                                    <v-radio-group class="ma-0 pa-0" v-model="accionCantidadTimbre" row>
                                                        <v-radio label="Agregar" value="agregar"></v-radio>
                                                        <v-radio label="Restar" value="restar"></v-radio>
                                                    </v-radio-group>
                                                </v-container>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pa-0">
                                                <ValidationProvider v-slot="{ errors }" name="Cantidad" rules="required">
                                                    <v-text-field 
                                                        outlined 
                                                        label="Cantidad" 
                                                        v-model.number="perfilFacturacion.timbres"
                                                        v-mask="'############'"
                                                        type="number"
                                                        :error-messages="errors"
                                                        required
                                                    >    
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button 
                                    class="btnCerrar" 
                                    @click="cerrarModal()"
                                    :disabled="isSaving"
                                >
                                    Cerrar
                                </button>  
                                <v-btn 
                                    v-if="accion == 'cambioContrasenia'"
                                    class="btnGuardar" 
                                    @click="actualizarContrasenia()"
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                                <v-btn 
                                    v-if="accion == 'actualizarTimbres'"
                                    class="btnGuardar" 
                                    @click="actualizarTimbres()"
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/PerfilesFacturacion';
import Notify from '@/plugins/notify';
import apiClientes from "@/api/clientes";
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiPerfilFacturacion from '@/api/nominas/PerfilesFacturacion';
import registroPatronal from '../api/nominas/registroPatronal';


export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Registro patronal',
                    name: 'registroPatronal.registro_patronal',
                    filterable: true
                },
                {
                    label: 'Correo',
                    name: 'email',
                    filterable: true
                },
                {
                    label: 'Ilimitado',
                    name: 'ilimitado',
                    filterable: true
                },
                {
                    label: 'Timbres',
                    name: 'timbres',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            tableData                   : {},
            filters                     : {},
            isLoading                   : true,
            isLoadingModal              : false,
            paginationData              : {
                numberItems             : 10,
                numberPage              : 1
            },
            perfilFacturacion           : {
                usuario_facturacion_id  : null,
                registro_patronal_id    : null,
                email                   : null,
                password                : null,
                ilimitado               : false,
                timbres                 : null,
            },
            datosLogin                  : null,
            rol                         : null,
            accion                      : null,
            tituloModal                 : null,
            dialog                      : false,
            dialogAcciones              : false,
            isSaving                    : false,
            imagen                      : '',
            clientes                    : [],
            itemsRegistrosPatronales    : [],
            cliente_value               : null,
            showContrasenia             : false,
            show1                       : false,
            contraseniaActual           : null,
            contraseniaNueva            : null,
            confirmacion                : null,
            isLoadingModalAcciones      : false,
            contraseniaActualizar       : null,
            accionCantidadTimbre        : 'agregar',
            filterCorreo                : null,
            filterRegistroPatronal      : null,
        }
    },
    watch: {
        cliente_value: function(val = null, oldValue) {
            if(val != null){
                if(val != oldValue && oldValue != null){
                    this.perfilFacturacion.registro_patronal_id = null;
                }
                this.getRegistroPatronal();
            }
        },
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        abrirModal(accion, data = null){
            this.accion         = accion;
            this.isLoadingModal = true;
            this.isLoadingModalAcciones = true;
            this.getClientes();

            if(accion == "add"){
                this.tituloModal    = "Registro de usuarios";
                this.imagen         = "/static/modal/usuarioFacturacionCreate.png";
                this.isLoadingModal  = false;
                setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
                
            } 
            else if(accion == "update") {
                this.tituloModal                                = "Actualizar perfil de facturación";
                this.imagen                                     = "/static/modal/usuarioFacturacionUpdate.png";
                this.perfilFacturacion.usuario_facturacion_id   = data.id;
                this.cliente_value                              = data.cliente_id;
                this.perfilFacturacion.registro_patronal_id     = data.registroPatronal.id;
                this.perfilFacturacion.email                    = data.email;
                this.perfilFacturacion.password                 = data.password;
                this.perfilFacturacion.ilimitado                = data.ilimitado;
                this.perfilFacturacion.activo                   = true;
                this.isLoadingModal  = false;
                setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);

            }
            else if(accion == "cambioContrasenia"){
                this.tituloModal                                = "¿Cambiar contraseña?";
                this.perfilFacturacion.usuario_facturacion_id   = data.id;
                this.isLoadingModalAcciones = false;
                setTimeout(function(){ document.getElementById("btnModalAcciones").click(); }, 100);
            }
            else if( accion == "actualizarTimbres"){
                this.tituloModal                                = "Administrar cantidad de timbres";
                this.perfilFacturacion.usuario_facturacion_id   = data.id;
                this.isLoadingModalAcciones = false;
                setTimeout(function(){ document.getElementById("btnModalAcciones").click(); }, 100); 
            }
            
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.dialogAcciones = false;
            this.resetValues();
        },
        resetValues() {
            this.perfilFacturacion      = {
                usuario_facturacion_id  : null,
                registro_patronal_id    : null,
                email                   : null,
                password                : null,
                ilimitado               : false,
                timbres                 : null,
            };
            this.cliente_value              = null;
            this.contraseniaActualizar      = null;
            this.filterCorreo               = null;
            this.filterRegistroPatronal     = null;
            this.itemsRegistrosPatronales   = [];

            if(this.$refs.form){
                this.$nextTick(() => {
                    this.$refs.form.reset();
                });
            }
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas desactivar el registro " + data.nombre + "?",
                "Desactivar",
                () => {
                    /* this.isLoading = true;
                    apiPerfilFacturacion.delete(data.id).then(response => {
                        Notify.Success("Perfil eliminado", "El perfil ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.perfilesFacturacion.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    }); */
                }
            )
        },
       
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                if(this.perfilFacturacion.timbres == null){
                    this.perfilFacturacion.timbres = 0;
                }
                if(this.accion === "add") {
                    apiPerfilFacturacion.store(this.perfilFacturacion).then((response) => {
                        Notify.Success("Operación exitosa", "El perfil se guardó satisfactoriamente.");
                        this.$apollo.queries.perfilesFacturacion.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiPerfilFacturacion.update(this.perfilFacturacion).then((response) => {
                        Notify.Success("Operación exitosa", "El perfil se actualizó satisfactoriamente.");
                        this.$apollo.queries.perfilesFacturacion.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        async getClientes() {
            let param = { activo: true, paginate: false };
            await apiClientes.getClientes(param).then(response => {
                    this.clientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },
        async getRegistroPatronal(){
            this.itemsRegistrosPatronales = [];
            let parametros = { activo:true, paginate:false, order:"asc", cliente_id: this.cliente_value }
            await apiRegistroPatronal.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.claveRegistroPatronal = item.clave + ' - ' + item.registro_patronal;
                        this.itemsRegistrosPatronales.push(item);
                    })
                }
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },
        lowercase() {
            this.perfilFacturacion.correo = this.perfilFacturacion.correo.toLowerCase();
        },
        actualizarContrasenia(){
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                let param = { 
                    usuario_facturacion_id: this.perfilFacturacion.usuario_facturacion_id,   
                    password: this.contraseniaActualizar
                }

                apiPerfilFacturacion.updatePassword(param).then((response) => {
                    Notify.Success("Operación exitosa", "La contraseña se actualizo satisfactoriamente.");
                    this.$apollo.queries.perfilesFacturacion.refetch();
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isLoading = false;
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
            });
        },
        actualizarTimbres(){
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                let param = { 
                    usuario_facturacion_id: this.perfilFacturacion.usuario_facturacion_id,   
                    timbres: this.perfilFacturacion.timbres
                }

                if(this.accionCantidadTimbre == 'agregar'){
                    apiPerfilFacturacion.updateTimbres(param).then((response) => {
                        Notify.Success("Operación exitosa", "El número de timbres se actualizo satisfactoriamente.");
                        this.$apollo.queries.perfilesFacturacion.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accionCantidadTimbre == 'restar'){
                    Notify.ErrorToast("Api restar");
                    this.isSaving = false;
                }
            });
        },
        async loadModalData() {
            await this.resetValues();
            await this.getClientes();
        },
        setFilters() {
            this.filters = { AND:[]};
            
            let cliente = this.cliente_value;
            let registroPatronal = this.filterRegistroPatronal;
            let correo  = this.filterCorreo;

            this.isLoading = true;
            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente})
            }

            if(registroPatronal != null && registroPatronal != undefined && registroPatronal != ""){
                this.filters.AND.push({column:'REGISTRO_PATRONAL_ID', value:registroPatronal})
            }

            if(correo != null && correo != undefined && correo != ""){
                this.filters.AND.push({column:'EMAIL', operator:'ILIKE', value:"%"+correo+"%"});
            }
            
            this.$apollo.queries.perfilesFacturacion.refetch();
            this.resetValues();
        },
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;

        if (this.rol != "root") {
            this.$router.push('/dashboard');
        }
    },
    mounted(){
        this.$apollo.queries.perfilesFacturacion.skip = false;
    },
    apollo: {
        perfilesFacturacion: {
            query       : queries.perfilesFacturacionTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereConditions     : this.filters,
                    numberItems         : this.paginationData.numberItems,
                    numberPage          : this.paginationData.numberPage,
                    fieldOrder          : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'id',
                    Order               : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.perfilesFacturacion;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .tituloOtrasAcciones{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #828282;
    }
    .code input {
        text-transform: lowercase;
    }

</style>
 