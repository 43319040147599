import gql from 'graphql-tag';

const perfilesFacturacionTableQuery = gql`
    query perfilesFacturacion($whereConditions:PerfilesFacturacionWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        perfilesFacturacion(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id
                registroPatronal {
                    id,
                    nombre,
                    rfc,
                    clave,
                    registro_patronal,
                }
                cliente_id
                cliente{
                    id
                }
                email
                uuid
                timbres
                ilimitado
                activo
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { perfilesFacturacionTableQuery };

export default queries;
